@import './button/Button.css';
@import './input/Input.css';
@import './element-wrapper/ElementWrapper.css';
@import './tile/Tile.css';
@import './menu/Menu.css';
@import './menuLib/MenuLib.css';
@import 'swimlaneLib/SwimlaneLib.css';
@import './tag/Tag.css';
@import './slide/Slide.css';
@import './dropdown/Dropdown.css';
@import './subMenu/subMenu.css';
@import './shoppingCart/softBundle/SoftBundle.css';
@import './shoppingCart/softBundle/softBundleItem/SoftBundleItem.css';
@import './mySubscriptionsPage/FixedLayout/mySubscriptionList/mySubscriptionSoftBundleTile/MySubscriptionSoftBundleTile.css';
@import './tilesList/SingleContentBlock/SingleContentBlock.css';
@import './tilesList/MultipleContentBlocks/MultipleContentBlocks.css';
@import './tilesList/MultipleContentBlocks/MultipleContentBlocksTile/MultipleContentBlocksTile.css';
@import './offerPopup/hardBundlePlanTile/HardBundlePlanTile.css';

:root {
    --text-color: black;
}
